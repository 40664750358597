import { FC } from "react";
import { StyledLogo } from "./style";
import logo from "../../static/logo.png"

const Logo: FC = () => {
    return (
        <StyledLogo path="/">
            <img src={logo}>
            </img>
        </StyledLogo>
    );
};

export default Logo;
