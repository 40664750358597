import React, {lazy} from "react";
import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
} from "react-feather";
import {
    DropdownToggle,
    Dropdown
} from "../../ui/dropdown/dropdown";
import{
    Avatar,
    AvatarInitial,
}from "../../ui/avatar/avatar"
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { BroadcastChannel } from 'broadcast-channel';


const Help = lazy(() => import("../../../../pages/help/Help"));
// "./pages/help/Help"

const logoutChannel = new BroadcastChannel('logout');

export const logOut = () => {
    logoutChannel.postMessage("Logout")
    localStorage.removeItem("token" )
    window.location.href = window.location.origin + "/login";
}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logOut();
        logoutChannel.close();
              
    }
}

const ProfileDropdown: React.FC = () => {

    // const logOut=()=>{

    //     localStorage.removeItem('token');    
    //   }


    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm">
                    <AvatarInitial>RDC</AvatarInitial>
                </StyledAvatar>
            </DropdownToggle>
            <StyledDropMenu>
                <Avatar size="lg">
                    <AvatarInitial>RDC</AvatarInitial>
                </Avatar>
                <StyledAuthorName>KullanıcıAdı</StyledAuthorName>
                <StyledAuthorRole>KullanıcıRolü</StyledAuthorRole>
                <StyledDropItem path="/profile/0">
                    <Edit3 size="24" />
                    Profili incele
                </StyledDropItem>
                <StyledDivider />
                <StyledDropItem
                    
                    path="/help"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Yardım
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Hesap ayarları
                </StyledDropItem>
                <StyledDropItem path="/profile" mt="10px">
                    <Settings size="24" />
                    Gizlilik ayarları
                </StyledDropItem>
                <StyledDropItem path="/login" mt="10px">
                {/* <Button onClick ={logOut}
                    variant="contained"
                    color="default"
                    startIcon={<ExitToAppIcon />}
                >
                ÇIKIŞ YAP
                </Button> */}
                <button onClick={logOut} style={{ fontSize: '14px', padding: '6px 20px', display: 'flex', alignItems: 'center'}}>
                    <ExitToAppIcon style={{ marginRight: '8px' }} /> 
                    ÇIKIŞ YAP
                </button>
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
