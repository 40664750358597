import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MessageSquare ,Bell} from "react-feather";
import { DropdownToggle, Dropdown } from "../../../components/src/ui/dropdown/dropdown";
import { Media, MediaLeft } from "../../../components/src/ui/media/media";
import { Avatar, AvatarInitial } from "../../../components";
import {
    StyledDropMenu,
    StyledDropHeader,
    StyledDropItem,
    StyledDropFooter,
    StyledBadge,
    IconStyle
} from "./style";
import { StyledMediaBody, StyledStrong, StyledText, StyledSpan } from "./style";
import { getBirthdayList } from "../../../redux/actions/memberAction";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { FaBirthdayCake } from "react-icons/fa";
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import { width } from "styled-system";


const BirthdayDropdown: React.FC = () => {

    const dis = useDispatch();

    // const { birthdayList } = useSelector((state: RootStateOrAny) => state.birthdayList);

    // function getBirthday() {
    //     dis(getBirthdayList());
    // }

    // useEffect(() => {
    //     getBirthday();

    // },[])


    return (
        <Dropdown direction="down">
            <DropdownToggle variant="texted">
                <Bell className="header-icon" />
                {/* <StyledBadge></StyledBadge> */}
            </DropdownToggle>
            {/* <StyledDropMenu>
                <StyledDropHeader > DOĞUM GÜNLERİ </StyledDropHeader>
                {Object.values(birthdayList).length > 0 &&

                    birthdayList && Object.values(birthdayList?.data.map((item: any, index: any) => (
                        <StyledDropItem path="/apps/chat" key={index}>

                            <Media  >
                                <MediaLeft>
                                <IconStyle>
                        <FaBirthdayCake style={{ alignItems: 'center', textAlign: 'center', margin: 'auto', color: '#EF9A9A	' ,width:'30' ,height:'30'}} />
                      </IconStyle>
                                </MediaLeft>
                                <StyledMediaBody>
                                    <br/>
                                    <StyledText><strong>{item?.MemberName}</strong></StyledText>
                                    <StyledSpan>{item?.SpecialDayDate}</StyledSpan>
                                </StyledMediaBody>
                            </Media>

                        </StyledDropItem>)))}

                <StyledDropFooter>
                </StyledDropFooter>
            </StyledDropMenu> */}
        </Dropdown>
    );
};

export default BirthdayDropdown;
