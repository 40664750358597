import {
    Calendar,
    Users,
    FileText,
    User,
    TrendingUp,
    Monitor,
    Bookmark,
    Tag,
    DollarSign,
    Star,
    Award,
    Search,
    UserCheck,

} from "react-feather";
import { FaRegMoneyBillAlt, FaRegFileAlt, FaChartBar } from "react-icons/fa";
const roles = localStorage.getItem('roles');

const menus = [
    {
        id: 1,
        label: "Arama",
        url: "/",
        Icon: Search,
        submenu: [
            roles?.includes('CandidateSearch') ?
                {
                    id: 11,
                    label: "Aday",
                    url: "/personnelSearch",
                    Icon: Users,
                } : {
                    id: 11,
                    label: "",
                    url: "/",
                }
            ,
            roles?.includes('CandidateSearchPosition') ?
                {
                    id: 12,
                    label: "Pozisyona - Aday",
                    url: "/candidateForPosition",
                    Icon: UserCheck,
                } : {
                    id: 12,
                    label: "",
                    url: "/",
                },

        ],
    },

    {
        id: 2,
        label: "Rapor",
        url: "/",
        Icon: TrendingUp,
        submenu: [
            roles?.includes('ReportSalary') ?
                {
                    id: 21,
                    label: "Maaş",
                    url: "/salaryExpectation",
                    Icon: FaRegMoneyBillAlt,

                } : {
                    id: 21,
                    label: "",
                    url: "/",
                },
            roles?.includes('ReportTechGroup') ?
                {
                    id: 22,
                    label: "Teknik Grup Raporlama",
                    url: "/technicalGroupReport",
                    Icon: FaRegFileAlt,

                } : {
                    id: 22,
                    label: "",
                    url: "/",
                },
            roles?.includes('ReportDeleteUpdate') ?
                {
                    id: 23,
                    label: "Silme/Güncelleme İstatistikleri",
                    url: "/stateStatic",
                    Icon: FaChartBar,

                } : {
                    id: 23,
                    label: "",
                    url: "/",
                },


        ],
    },
    {
        id: 3,
        label: "Performans",
        url: "/",
        Icon: Tag,
        submenu: [
            roles?.includes('PerformanceEvaulation') ?
                {
                    id: 31,
                    label: "Çalışan Değerlendirme",
                    url: "/addperformance",
                } : {
                    id: 31,
                    label: "",
                    url: "/",
                },
            roles?.includes('PerformanceCriteria') ?

                {
                    id: 32,
                    label: "Değerlendirme Kriterleri",
                    url: "/addcriteria",
                } : {
                    id: 32,
                    label: "",
                    url: "/",
                },
            roles?.includes('PerformanceList') ?

                {
                    id: 33,
                    label: "Performans Listeleme",
                    url: "/performancelist",
                } : {
                    id: 33,
                    label: "",
                    url: "/",
                },

        ],
    },

    {
        id: 4,
        label: "HrDashboard",
        url: "/",
        Icon: Award,
        submenu: [
            roles?.includes('PositionList') ?
                {
                    id: 41,
                    label: "Pozisyon Listesi",
                    url: "/positionList",
                } : {
                    id: 41,
                    label: "",
                    url: "/",
                },

        ],
    },
    {
        id: 5,
        label: "Parametre",
        url: "/",
        Icon: Award,
        submenu: [
            roles?.includes('ParameterCompetence') ?
                {
                    id: 42,
                    label: "Yetkinlik",
                    url: "/addnewparameter",
                } : {
                    id: 42,
                    label: "",
                    url: "/",
                },

        ],
    },
    {
        id: 6,
        label: "Hedef",
        url: "/",
        Icon: Award,
        submenu: [
            //role oluşturulacak
            roles?.includes('PersonnelTarget') ?
                {
                    id: 61,
                    label: "Personel Hedef",
                    url: "/personneltarget",
                } : {
                    id: 61,
                    label: "",
                    url: "/",
                },

        ],
    },
    {
        id: 7,
        label: "CV Formatı",
        url: "/",
        Icon: Award,
        submenu: [
            {
                id: 62,
                label: "CV Formatı",
                url: "/cvFormat",
            }
        ],
    },
    {
        id: 8,
        label: "Ses Dosyası",
        url: "/",
        Icon: Award,
        submenu: [
            {
                id: 63,
                label: "Dosya Yükleme",
                url: "/uploadfile",
            }
        ],
    }
];

export default menus;
