
import {
  GET_MEMBER_ERROR, GET_BIRTHDAY_LIST_ERROR, GET_MEMBER_SUCCESS, Member, memberActions, GET_MEMBER_ALL_LİST_SUCCESS, GET_MEMBER_ALL_LİST_ERROR, AllMember, UserDetail,
  GET_MY_USER_DETAIL_SUCCESS, GET_MY_USER_DETAIL_ERROR, GET_ALL_PERSONNEL_LİST_ERROR, GET_ALL_PERSONNEL_LİST_SUCCESS, AllPersonnel, AllCriteria,
  GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS, GET_ALL_PERFORMANCE_CRİTERİA_ERROR, Companies, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR, Customer, GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS,
  GET_CUSTOMER_LİST_BY_COMPANYID_ERROR, Team, GET_TEAM_LİST_BY_CUSTOMER_ERROR, GET_TEAM_LİST_BY_CUSTOMER_SUCCESS, GET_MANAGER_BY_CUSTOMERID_SUCCESS,
  GET_MANAGER_BY_CUSTOMERID_ERROR, Manager, GET_EMPLOYEE_BY_INPUT_SUCCESS, GET_EMPLOYEE_BY_INPUT_ERROR, Employee, GET_LİST_PERFORMANCE_EVULATİON_SUCCESS,
  GET_LİST_PERFORMANCE_EVULATİON_ERROR, Performance, PerformanceDetail, GET_PERFORMANCE_DETAİL_ERROR, GET_PERFORMANCE_DETAİL_SUCCESS, BirthdayList, GET_BIRTHDAY_LIST_SUCCESS,
  CandidateList, GET_ALL_CANDIDATE_LİST_SUCCESS, GET_ALL_CANDIDATE_LİST_ERROR, TechnicalList, GET_ALL_TECHNICAL_GROUP_SUCCESS, GET_ALL_TECHNICAL_GROUP_ERROR,
  TechnicalGroupSalaryExpectationList, YearsOfExperienceSalaryExpectationList, GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS, GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR,
  GET_COLLAGE_LİST_ERROR, GET_COLLAGE_LİST_SUCCESS, CollageList, GET_COLLAGE_DEPARTMENT_LİST_ERROR, GET_COLLAGE_DEPARTMENT_LİST_SUCCESS, CollageDepartmentList,
  GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR, GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS, MemberProjectList, GET_MEMBER_PROJECT_LİST_SUCCESS,
  GET_MEMBER_PROJECT_LİST_ERROR, MemberCourseList, MemberTechnicList, GET_MEMBER_COURSE_LİST_SUCCESS, GET_MEMBER_COURSE_LİST_ERROR, GET_MEMBER_TECHNİC_LİST_SUCCESS,
  GET_MEMBER_TECHNİC_LİST_ERROR, PersonalAbilityList, GET_PERSONAL_ABİLİTY_LİST_SUCCESS, GET_PERSONAL_ABİLİTY_LİST_ERROR, MemberCollageList, MemberBadPersonalAbilityList,
  MemberGoodPersonalAbilityList, GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR, GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS, GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR, GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS,
  GET_MEMBER_COLLAGE_LİST_SUCCESS, GET_MEMBER_COLLAGE_LİST_ERROR, GET_LANGUAGE_LİST_ERROR, GET_LANGUAGE_LİST_SUCCESS, MemberLanguageList, GET_MEMBER_LANGUAGE_LİST_SUCCESS,
  GET_MEMBER_LANGUAGE_LİST_ERROR, ByTrendOverTimeList, GET_ALL_TREND_OVER_TIME_SUCCESS, GET_ALL_TREND_OVER_TIME_ERROR, PositionList, GET_POSITION_LIST_SUCCESS, GET_POSITION_LIST_ERROR, PositionListByCompany,
  GET_POSITION_LIST_BY_COMPANY_SUCCESS, GET_POSITION_LIST_BY_COMPANY_ERROR, PositionListByProject, GET_POSITION_LIST_BY_PROJECT_SUCCESS, GET_POSITION_LIST_BY_PROJECT_ERROR, CustomerList, GET_ALL_CUSTOMER_SUCCESS, GET_ALL_CUSTOMER_ERROR,
  GET_MEMBER_DOMAİN_LİST_ERROR, GET_MEMBER_DOMAİN_LİST_SUCCESS, MemberDomainList, AllSubStatusList, GET_ALL_SUB_STATUS_LİST_ERROR, GET_ALL_SUB_STATUS_LİST_SUCCESS, GET_MEMBER_TECHNİCAL_DETAİL_ERROR, GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS, MemberTechnicDetailList, PositionListTechnicGroup,
  GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS, GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR, InterviwerList, GET_ALL_INTERVIWER_LİST_SUCCESS, GET_ALL_INTERVIWER_LİST_ERROR, GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS, GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR, TechnicGroupNumberOfPeopleList,
  GET_TECHNIC_GROUP_REPORT_SUCCESS, GET_TECHNIC_GROUP_REPORT_ERROR, TechnicGroupReportList, TechnicalSelectTreeList, GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS, GET_TECHNICICAL_SELECT_TREE_LIST_ERROR, GET_MAIN_TECHNOLOGIES_LIST_SUCCESS, GET_MAIN_TECHNOLOGIES_LIST_ERROR, MainTechnologiesList, SubTechnologiesList,
  GET_SUB_TECHNOLOGIES_LIST_SUCCESS, GET_SUB_TECHNOLOGIES_LIST_ERROR, GET_DOMAİN_LİST_SUCCESS, GET_DOMAİN_LİST_ERROR, DomainList, GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS, GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR, PersonalCommunicationLevelList, GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS, GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR,
  MemberPersonalSkillList, GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS, GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR, SubTechnologiesListbyMainGroup, TechnicGroupMainList, GET_TECHNİC_GROUP_MAİN_LİST_ERROR, GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS, GET_MEMBER_TECHNİC_GROUP_LİST_ERROR, GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS, MemberTechnicGroupList,
  GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR, GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS, GET_STATUS_STATİSTİC_LİST_ERROR, GET_STATUS_STATİSTİC_LİST_SUCCESS, StatusStatisticList, StatusStatisticbyMemberList, GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR, GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS, AllMemberListForPosition,
  LanguageList, LanguageLevelList, GET_LANGUAGE_LEVEL_LİST_SUCCESS, GET_LANGUAGE_LEVEL_LİST_ERROR, GET_DOMAİN_MAİN_GROUP_LİST_ERROR, GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS, GET_DOMAİN_SUB_GROUP_LİST_ERROR, GET_DOMAİN_SUB_GROUP_LİST_SUCCESS, DomainMainGroupList, DomainSubGroupList
} from "../../redux/actions/actionTypes";

interface MemberState {  //Task ile ilgili datalarımızı taskProps'da tutuyoruz. Başka bir nesneye ihtiyaç duymanız halinde ilave edebilisiniz. 
  memberList: Member[];
  message: string;
}

//state'mizi oluşturuyoruz. Dataları burada barındırıypruz. TaskState'tipinde olduğunu belittyoruz.
const initialState: MemberState = {
  memberList: [],
  message: "",
};

interface MemberAllState {
  memberAllList: AllMember[];
  message: string;
};
const initialStateAllMember: MemberAllState = {
  memberAllList: [],
  message: "",
};

interface MyUserDetailState {
  myUserDetailList: UserDetail[];
  message: string;
}

const initialStateMyUserDetail: MyUserDetailState = {
  myUserDetailList: [],
  message: "",
}

interface PersonnelAllState {
  personnelAllList: AllPersonnel[];
  message: string;
};
const initialStateAllPersonnel: PersonnelAllState = {
  personnelAllList: [],
  message: "",
};

interface AllCriteriaState {
  allCriteriaList: AllCriteria[];
  message: string;
};
const initialStateAllCriteria: AllCriteriaState = {
  allCriteriaList: [],
  message: "",
};

interface CompaniesState {
  companiesList: Companies[]
  message: string;
}

const initialStateCompanies: CompaniesState = {
  companiesList: [],
  message: ""
}
interface CustomerState {
  customerList: Customer[]
  message: string;
}

const initialStateCustomer: CustomerState = {
  customerList: [],
  message: ""
}
interface TeamState {
  teamList: Team[];
  message: string;
}
const initialStateTeam: TeamState = {
  teamList: [],
  message: ""
}

interface ManagerState {
  managerList: Manager[];
  message: string;
}
const initialStateManager: ManagerState = {
  managerList: [],
  message: ""
}

interface EmployeeState {
  employeeList: Employee[];
  message: string;
}
const initialStateEmployee: EmployeeState = {
  employeeList: [],
  message: ""
}

interface PerformanceState {
  performanceList: Performance[];
  message: string;
}
const initialStatePerformance: PerformanceState = {
  performanceList: [],
  message: ""
}
interface PerformanceDetailState {
  performanceDetailList: PerformanceDetail[];
  message: string;
}
const initialStatePerformanceDetail: PerformanceDetailState = {
  performanceDetailList: [],
  message: ""
}
interface BirthdayState {
  birthdayList: BirthdayList[];
  message: string
}
const initialStateBirthday: BirthdayState = {
  birthdayList: [],
  message: ""
}
interface CandidateState {
  candidateList: CandidateList[];
  message: string
}
const initialStateCandidate: CandidateState = {
  candidateList: [],
  message: ""
}

interface TechnicalGroupState {
  technicalList: TechnicalList[];
  message: string
}
const initialStateTechnicalGroup: TechnicalGroupState = {
  technicalList: [],
  message: ""
}

interface TechnicalGroupSalaryExpectationState {
  technicalGroupSalaryExpectationList: TechnicalGroupSalaryExpectationList[];
  messageTechnicalGroupSalaryExpectationList: string
}

const initialStateTechnicalGroupSalaryExpectation: TechnicalGroupSalaryExpectationState = {
  technicalGroupSalaryExpectationList: [],
  messageTechnicalGroupSalaryExpectationList: ""
}

interface YearsOfExperienceSalaryExpectationState {
  yearsOfExperienceSalaryExpectationList: YearsOfExperienceSalaryExpectationList[];
  messageYearsOfExperienceSalaryExpectationList: string
}

const initialStateYearsOfExperienceSalaryExpectation: YearsOfExperienceSalaryExpectationState = {
  yearsOfExperienceSalaryExpectationList: [],
  messageYearsOfExperienceSalaryExpectationList: ""
}

interface TrendOverTimeState {
  trendOverTimeList: ByTrendOverTimeList[];
  messageTrendOverTimeList: string
}

const initialStatebyTrendOverTime: TrendOverTimeState = {
  trendOverTimeList: [],
  messageTrendOverTimeList: ""
}

interface CollageListState {
  collageList: CollageList[];
  message: string
}

const initialStateCollageList: CollageListState = {
  collageList: [],
  message: ""
}

interface CollageDepartmentListState {
  collageDepartmentList: CollageDepartmentList[];
  message: string
}

const initialStateCollageDepartmentList: CollageDepartmentListState = {
  collageDepartmentList: [],
  message: ""
}

interface MemberProjectListListState {
  memberProjectList: MemberProjectList[];
  message: string
}

const initialStateMemberProjectList: MemberProjectListListState = {
  memberProjectList: [],
  message: ""
}

interface MemberCourseListState {
  memberCourseList: MemberCourseList[];
  message: string
}

const initialStateMemberCourseList: MemberCourseListState = {
  memberCourseList: [],
  message: ""
}

interface MemberTechnicListState {
  memberTechnicList: MemberTechnicList[];
  message: string
}

const initialStateMemberTechnicList: MemberTechnicListState = {
  memberTechnicList: [],
  message: ""
}

interface PersonalAbilityListState {
  personalAbilityList: PersonalAbilityList[];
  message: string
}

const initialStatePersonalAbilityList: PersonalAbilityListState = {
  personalAbilityList: [],
  message: ""
}

interface MemberGoodPersonalAbilityListState {
  memberGoodPersonalAbilityList: MemberGoodPersonalAbilityList[];
  message: string
}

const initialStateMemberGoodPersonalAbilityList: MemberGoodPersonalAbilityListState = {
  memberGoodPersonalAbilityList: [],
  message: ""
}

interface MemberBadPersonalAbilityListState {
  memberBadPersonalAbilityList: MemberBadPersonalAbilityList[];
  message: string
}

const initialStateMemberBadPersonalAbilityList: MemberBadPersonalAbilityListState = {
  memberBadPersonalAbilityList: [],
  message: ""
}

interface MemberCollageListState {
  memberCollageList: MemberCollageList[];
  message: string
}

const initialStateMemberCollageList: MemberCollageListState = {
  memberCollageList: [],
  message: ""
}

interface MemberLanguageListState {
  memberLanguageList: MemberLanguageList[];
  message: string
}

const initialStateMemberLanguageList: MemberLanguageListState = {
  memberLanguageList: [],
  message: ""
}

interface PositionListState {
  positionList: PositionList[];
  message: string
}

const initialStatePositionList: PositionListState = {
  positionList: [],
  message: ""
}

interface PositionListingByCompanyState {
  positionListByCompany: PositionListByCompany[];
  messagePositionListByCompany: string
}

const initialStatePositionListingByCompany: PositionListingByCompanyState = {
  positionListByCompany: [],
  messagePositionListByCompany: ""
}

interface PositionListingByProjectState {
  positionListByProject: PositionListByProject[];
  message: string
}

const initialStatePositionListingByProject: PositionListingByProjectState = {
  positionListByProject: [],
  message: ""
}

interface CustomerListState {
  customerList: CustomerList[];
  message: string
}

const initialStateCustomerList: CustomerListState = {
  customerList: [],
  message: ""
}

interface MemberDomainListState {
  memberDomainList: MemberDomainList[];
  message: string
}

const initialStateMemberDomainList: MemberDomainListState = {
  memberDomainList: [],
  message: ""
}

interface AllSubStatusListState {
  allSubStatusList: AllSubStatusList[];
  message: string
}

const initialStateAllSubStatusList: AllSubStatusListState = {
  allSubStatusList: [],
  message: ""
}

interface AllInterviwerListState {
  allInterviwerList: InterviwerList[];
  message: string
}

const initialStateAllInterviwerList: AllInterviwerListState = {
  allInterviwerList: [],
  message: ""
}

interface MemberTechnicDetailListState {
  memberTechnicDetailList: MemberTechnicDetailList[];
  message: string
}

const initialStateMemberTechnicDetailList: MemberTechnicDetailListState = {
  memberTechnicDetailList: [],
  message: ""
}

interface PositionListByTechnicGroupState {
  positionListByTechnicGroup: PositionListTechnicGroup[];
  message: string
}

const initialStatePositionListByTechnicGroup: PositionListByTechnicGroupState = {
  positionListByTechnicGroup: [],
  message: ""
}

interface TechnicGroupNumberOfPeopleListState {
  technicGroupNumberOfPeopleList: TechnicGroupNumberOfPeopleList[];
  message: string
}

const initialStateTechnicGroupNumberOfPeopleList: TechnicGroupNumberOfPeopleListState = {
  technicGroupNumberOfPeopleList: [],
  message: ""
}

interface TechnicGroupReportListState {
  technicGroupReportList: TechnicGroupReportList[];
  message: string
}

const initialStateTechnicGroupReportList: TechnicGroupReportListState = {
  technicGroupReportList: [],
  message: ""
}

interface TechnicalSelectTreeListState {
  technicalSelectTreeList: TechnicalSelectTreeList[];
  message: string
}

const initialStateTechnicalSelectTreeList: TechnicalSelectTreeListState = {
  technicalSelectTreeList: [],
  message: ""
}

interface MainTechnologiesListState {
  mainTechnologiesList: MainTechnologiesList[];
  message: string
}

const initialStateMainTechnologiesList: MainTechnologiesListState = {
  mainTechnologiesList: [],
  message: ""
}

interface SubTechnologiesListState {
  subTechnologiesList: SubTechnologiesList[];
  message: string
}

const initialStateSubTechnologiesList: SubTechnologiesListState = {
  subTechnologiesList: [],
  message: ""
}
//
interface DomainListState {
  domainList: DomainList[];
  message: string
}

const initialStateDomainList: DomainListState = {
  domainList: [],
  message: ""
}

interface LanguageListState {
  languageList: LanguageList[];
  message: string
}

const initialStateLanguageList: LanguageListState = {
  languageList: [],
  message: ""
}
interface LanguageLevelListState {
  languageLevelList: LanguageLevelList[];
  message: string
}

const initialStateLanguageLevelList: LanguageLevelListState = {
  languageLevelList: [],
  message: ""
}
interface PersonalCommunicationLevelListState {
  personalCommunicationLevelList: PersonalCommunicationLevelList[];
  message: string
}

const initialStatePersonalCommunicationLevelList: PersonalCommunicationLevelListState = {
  personalCommunicationLevelList: [],
  message: ""
}

interface MemberPersonalSkillListState {
  memberPersonalSkillList: MemberPersonalSkillList[];
  message: string
}

const initialStateMemberPersonalSkillList: MemberPersonalSkillListState = {
  memberPersonalSkillList: [],
  message: ""
}

interface SubTechnologiesListbyMainGroupState {
  subTechnologiesListbyMainGroup: SubTechnologiesListbyMainGroup[];
  message: string
}

const initialStateSubTechnologiesListbyMainGroup: SubTechnologiesListbyMainGroupState = {
  subTechnologiesListbyMainGroup: [],
  message: ""
}

interface TechnicGroupMainListState {
  technicGroupMainList: TechnicGroupMainList[];
  message: string
}

const initialStateTechnicGroupMainList: TechnicGroupMainListState = {
  technicGroupMainList: [],
  message: ""
}

interface MemberTechnicGroupMainListState {
  memberTechnicGroupList: MemberTechnicGroupList[];
  message: string
}

const initialStateMemberTechnicGroupList: MemberTechnicGroupMainListState = {
  memberTechnicGroupList: [],
  message: ""
}

interface StatusStatisticListState {
  statusStatisticList: StatusStatisticList[];
  message: string
}

const initialStateStatusStatisticList: StatusStatisticListState = {
  statusStatisticList: [],
  message: ""
}

interface StatusStatisticbyMemberListState {
  statusStatisticbyMemberList: StatusStatisticbyMemberList[];
  message: string
}

const initialStateStatusStatisticbyMemberList: StatusStatisticbyMemberListState = {
  statusStatisticbyMemberList: [],
  message: ""
}

interface AllMemberListForPositionState {
  allMemberListForPosition: AllMemberListForPosition[];
  message: string
}

const initialStateAllMemberListForPosition: AllMemberListForPositionState = {
  allMemberListForPosition: [],
  message: ""
}

interface DomainMainGoroupListState {
  domainMainGroupList: DomainMainGroupList[];
  message: string
}

const initialStateDomainMainGoroupList: DomainMainGoroupListState = {
  domainMainGroupList: [],
  message: ""
}

interface DomainSubGoroupListState {
  domainSubGroupList: DomainSubGroupList[];
  message: string
}

const initialStateDomainSubGoroupList: DomainSubGoroupListState = {
  domainSubGroupList: [],
  message: ""
}

export function memberReducer(
  state: MemberState = initialState,
  action: memberActions,
): MemberState {
  switch (action.type) {
    case GET_MEMBER_SUCCESS: {
      return {
        ...state,
        memberList: action.payload,
      };
    }
    case GET_MEMBER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allMemberReducer(
  state: MemberAllState = initialStateAllMember,
  action: memberActions,
): MemberAllState {
  switch (action.type) {
    case GET_MEMBER_ALL_LİST_SUCCESS: {
      return {
        ...state,
        memberAllList: action.payload,
      };
    }
    case GET_MEMBER_ALL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function myUserDetailReducer(
  state: MyUserDetailState = initialStateMyUserDetail,
  action: memberActions,
): MyUserDetailState {
  switch (action.type) {
    case GET_MY_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        myUserDetailList: action.payload,
      };
    }
    case GET_MY_USER_DETAIL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}
export function allPersonnelReducer(
  state: PersonnelAllState = initialStateAllPersonnel,
  action: memberActions,
): PersonnelAllState {
  switch (action.type) {
    case GET_ALL_PERSONNEL_LİST_SUCCESS: {
      return {
        ...state,
        personnelAllList: action.payload,
      };
    }
    case GET_ALL_PERSONNEL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allCriteriaReducer(
  state: AllCriteriaState = initialStateAllCriteria,
  action: memberActions,
): AllCriteriaState {
  switch (action.type) {
    case GET_ALL_PERFORMANCE_CRİTERİA_SUCCESS: {
      return {
        ...state,
        allCriteriaList: action.payload,
      };
    }
    case GET_ALL_PERFORMANCE_CRİTERİA_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function companiesReducer(
  state: CompaniesState = initialStateCompanies,
  action: memberActions,
): CompaniesState {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesList: action.payload,
      };
    }
    case GET_COMPANIES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}

export function customerReducer(
  state: CustomerState = initialStateCustomer,
  action: memberActions,
): CustomerState {
  switch (action.type) {
    case GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS: {
      return {
        ...state,
        customerList: action.payload,
      };
    }
    case GET_CUSTOMER_LİST_BY_COMPANYID_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}
export function teamReducer(
  state: TeamState = initialStateTeam,
  action: memberActions,
): TeamState {
  switch (action.type) {
    case GET_TEAM_LİST_BY_CUSTOMER_SUCCESS: {
      return {
        ...state,
        teamList: action.payload,
      };
    }
    case GET_TEAM_LİST_BY_CUSTOMER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}

export function managerReducer(
  state: ManagerState = initialStateManager,
  action: memberActions,
): ManagerState {
  switch (action.type) {
    case GET_MANAGER_BY_CUSTOMERID_SUCCESS: {
      return {
        ...state,
        managerList: action.payload,
      };
    }
    case GET_MANAGER_BY_CUSTOMERID_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}

export function employeeReducer(
  state: EmployeeState = initialStateEmployee,
  action: memberActions,
): EmployeeState {
  switch (action.type) {
    case GET_EMPLOYEE_BY_INPUT_SUCCESS: {
      return {
        ...state,
        employeeList: action.payload,
      };
    }
    case GET_MANAGER_BY_CUSTOMERID_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}

export function performanceReducer(
  state: PerformanceState = initialStatePerformance,
  action: memberActions,
): PerformanceState {
  switch (action.type) {
    case GET_LİST_PERFORMANCE_EVULATİON_SUCCESS: {
      return {
        ...state,
        performanceList: action.payload,
      };
    }
    case GET_LİST_PERFORMANCE_EVULATİON_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }

}

export function performanceDetailReducer(
  state: PerformanceDetailState = initialStatePerformanceDetail,
  action: memberActions,
): PerformanceDetailState {
  switch (action.type) {
    case GET_PERFORMANCE_DETAİL_SUCCESS: {
      return {
        ...state,
        performanceDetailList: action.payload,
      };
    }
    case GET_PERFORMANCE_DETAİL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function birthdayReducer(
  state: BirthdayState = initialStateBirthday,
  action: memberActions,
): BirthdayState {
  switch (action.type) {
    case GET_BIRTHDAY_LIST_SUCCESS: {
      return {
        ...state,
        birthdayList: action.payload,
      };
    }
    case GET_BIRTHDAY_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function candidateReducer(
  state: CandidateState = initialStateCandidate,
  action: memberActions,
): CandidateState {
  switch (action.type) {
    case GET_ALL_CANDIDATE_LİST_SUCCESS: {
      return {
        ...state,
        candidateList: action.payload,
      };
    }
    case GET_ALL_CANDIDATE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function technicalReducer(
  state: TechnicalGroupState = initialStateTechnicalGroup,
  action: memberActions,
): TechnicalGroupState {
  switch (action.type) {
    case GET_ALL_TECHNICAL_GROUP_SUCCESS: {
      return {
        ...state,
        technicalList: action.payload,
      };
    }
    case GET_ALL_TECHNICAL_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function technicalGroupSalaryExpectationReducer(
  state: TechnicalGroupSalaryExpectationState = initialStateTechnicalGroupSalaryExpectation,
  action: memberActions,
): TechnicalGroupSalaryExpectationState {
  switch (action.type) {
    case GET_TECHNICAL_GROUP_SALARY_EXPECTATION_SUCCESS: {
      return {
        ...state,
        technicalGroupSalaryExpectationList: action.payload,
      };
    }
    case GET_TECHNICAL_GROUP_SALARY_EXPECTATION_ERROR: {
      return {
        ...state,
        messageTechnicalGroupSalaryExpectationList: action.payload,
      };
    }
    default:
      return state;
  }
}

export function yearsOfExperienceSalaryExpectationReducer(
  state: YearsOfExperienceSalaryExpectationState = initialStateYearsOfExperienceSalaryExpectation,
  action: memberActions,
): YearsOfExperienceSalaryExpectationState {
  switch (action.type) {
    case GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_SUCCESS: {
      return {
        ...state,
        yearsOfExperienceSalaryExpectationList: action.payload,
      };
    }
    case GET_YEARS_OF_EXPERIENCE_SALARY_EXPECTATION_ERROR: {
      return {
        ...state,
        messageYearsOfExperienceSalaryExpectationList: action.payload,
      };
    }
    default:
      return state;
  }
}

export function byTrendOverTimeReducer(
  state: TrendOverTimeState = initialStatebyTrendOverTime,
  action: memberActions,
): TrendOverTimeState {
  switch (action.type) {
    case GET_ALL_TREND_OVER_TIME_SUCCESS: {
      return {
        ...state,
        trendOverTimeList: action.payload,
      };
    }
    case GET_ALL_TREND_OVER_TIME_ERROR: {
      return {
        ...state,
        messageTrendOverTimeList: action.payload,
      };
    }
    default:
      return state;
  }
}

export function collageListReducer(
  state: CollageListState = initialStateCollageList,
  action: memberActions,
): CollageListState {
  switch (action.type) {
    case GET_COLLAGE_LİST_SUCCESS: {
      return {
        ...state,
        collageList: action.payload,
      };
    }
    case GET_COLLAGE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function collageDepartmentListReducer(
  state: CollageDepartmentListState = initialStateCollageDepartmentList,
  action: memberActions,
): CollageDepartmentListState {
  switch (action.type) {
    case GET_COLLAGE_DEPARTMENT_LİST_SUCCESS: {
      return {
        ...state,
        collageDepartmentList: action.payload,
      };
    }
    case GET_COLLAGE_DEPARTMENT_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberProjectListReducer(
  state: MemberProjectListListState = initialStateMemberProjectList,
  action: memberActions,
): MemberProjectListListState {
  switch (action.type) {
    case GET_MEMBER_PROJECT_LİST_SUCCESS: {
      return {
        ...state,
        memberProjectList: action.payload,
      };
    }
    case GET_MEMBER_PROJECT_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberCourseListReducer(
  state: MemberCourseListState = initialStateMemberCourseList,
  action: memberActions,
): MemberCourseListState {
  switch (action.type) {
    case GET_MEMBER_COURSE_LİST_SUCCESS: {
      return {
        ...state,
        memberCourseList: action.payload,
      };
    }
    case GET_MEMBER_COURSE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberTechnicListReducer(
  state: MemberTechnicListState = initialStateMemberTechnicList,
  action: memberActions,
): MemberTechnicListState {
  switch (action.type) {
    case GET_MEMBER_TECHNİC_LİST_SUCCESS: {
      return {
        ...state,
        memberTechnicList: action.payload,
      };
    }
    case GET_MEMBER_TECHNİC_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personalAbilityListReducer(
  state: PersonalAbilityListState = initialStatePersonalAbilityList,
  action: memberActions,
): PersonalAbilityListState {
  switch (action.type) {
    case GET_PERSONAL_ABİLİTY_LİST_SUCCESS: {
      return {
        ...state,
        personalAbilityList: action.payload,
      };
    }
    case GET_PERSONAL_ABİLİTY_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberGoodPersonalAbilityListReducer(
  state: MemberGoodPersonalAbilityListState = initialStateMemberGoodPersonalAbilityList,
  action: memberActions,
): MemberGoodPersonalAbilityListState {
  switch (action.type) {
    case GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_SUCCESS: {
      return {
        ...state,
        memberGoodPersonalAbilityList: action.payload,
      };
    }
    case GET_MEMBER_GOOD_PERSONAL_ABİLİTY_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberBadPersonalAbilityListReducer(
  state: MemberBadPersonalAbilityListState = initialStateMemberBadPersonalAbilityList,
  action: memberActions,
): MemberBadPersonalAbilityListState {
  switch (action.type) {
    case GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_SUCCESS: {
      return {
        ...state,
        memberBadPersonalAbilityList: action.payload,
      };
    }
    case GET_MEMBER_BAD_PERSONAL_ABİLİTY_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberCollageListReducer(
  state: MemberCollageListState = initialStateMemberCollageList,
  action: memberActions,
): MemberCollageListState {
  switch (action.type) {
    case GET_MEMBER_COLLAGE_LİST_SUCCESS: {
      return {
        ...state,
        memberCollageList: action.payload,
      };
    }
    case GET_MEMBER_COLLAGE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberLanguageListReducer(
  state: MemberLanguageListState = initialStateMemberLanguageList,
  action: memberActions,
): MemberLanguageListState {
  switch (action.type) {
    case GET_MEMBER_LANGUAGE_LİST_SUCCESS: {
      return {
        ...state,
        memberLanguageList: action.payload,
      };
    }
    case GET_MEMBER_LANGUAGE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function positionListReducer(
  state: PositionListState = initialStatePositionList,
  action: memberActions,
): PositionListState {
  switch (action.type) {
    case GET_POSITION_LIST_SUCCESS: {
      return {
        ...state,
        positionList: action.payload,
      };
    }
    case GET_POSITION_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function positionListByCompanyReducer(
  state: PositionListingByCompanyState = initialStatePositionListingByCompany,
  action: memberActions,
): PositionListingByCompanyState {
  switch (action.type) {
    case GET_POSITION_LIST_BY_COMPANY_SUCCESS: {
      return {
        ...state,
        positionListByCompany: action.payload,
      };
    }
    case GET_POSITION_LIST_BY_COMPANY_ERROR: {
      return {
        ...state,
        messagePositionListByCompany: action.payload,
      };
    }
    default:
      return state;
  }
}

export function positionListByProjectReducer(
  state: PositionListingByProjectState = initialStatePositionListingByProject,
  action: memberActions,
): PositionListingByProjectState {
  switch (action.type) {
    case GET_POSITION_LIST_BY_PROJECT_SUCCESS: {
      return {
        ...state,
        positionListByProject: action.payload,
      };
    }
    case GET_POSITION_LIST_BY_PROJECT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function customerListReducer(
  state: CustomerListState = initialStateCustomerList,
  action: memberActions,
): CustomerListState {
  switch (action.type) {
    case GET_ALL_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerList: action.payload,
      };
    }
    case GET_ALL_CUSTOMER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberDomainListReducer(
  state: MemberDomainListState = initialStateMemberDomainList,
  action: memberActions,
): MemberDomainListState {
  switch (action.type) {
    case GET_MEMBER_DOMAİN_LİST_SUCCESS: {
      return {
        ...state,
        memberDomainList: action.payload,
      };
    }
    case GET_MEMBER_DOMAİN_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allSubStatusListReducer(
  state: AllSubStatusListState = initialStateAllSubStatusList,
  action: memberActions,
): AllSubStatusListState {
  switch (action.type) {
    case GET_ALL_SUB_STATUS_LİST_SUCCESS: {
      return {
        ...state,
        allSubStatusList: action.payload,
      };
    }
    case GET_ALL_SUB_STATUS_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allInterviwerListReducer(
  state: AllInterviwerListState = initialStateAllInterviwerList,
  action: memberActions,
): AllInterviwerListState {
  switch (action.type) {
    case GET_ALL_INTERVIWER_LİST_SUCCESS: {
      return {
        ...state,
        allInterviwerList: action.payload,
      };
    }
    case GET_ALL_INTERVIWER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberTechnicDetailListReducer(
  state: MemberTechnicDetailListState = initialStateMemberTechnicDetailList,
  action: memberActions,
): MemberTechnicDetailListState {
  switch (action.type) {
    case GET_MEMBER_TECHNİCAL_DETAİL_SUCCESS: {
      return {
        ...state,
        memberTechnicDetailList: action.payload,
      };
    }
    case GET_MEMBER_TECHNİCAL_DETAİL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function positionListByTechnicGroupReducer(
  state: PositionListByTechnicGroupState = initialStatePositionListByTechnicGroup,
  action: memberActions,
): PositionListByTechnicGroupState {
  switch (action.type) {
    case GET_POSITION_LIST_BY_TECHNIC_GROUP_SUCCESS: {
      return {
        ...state,
        positionListByTechnicGroup: action.payload,
      };
    }
    case GET_POSITION_LIST_BY_TECHNIC_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function technicGroupNumberOfPeopleListReducer(
  state: TechnicGroupNumberOfPeopleListState = initialStateTechnicGroupNumberOfPeopleList,
  action: memberActions,
): TechnicGroupNumberOfPeopleListState {
  switch (action.type) {
    case GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_SUCCESS: {
      return {
        ...state,
        technicGroupNumberOfPeopleList: action.payload,
      };
    }
    case GET_TECHNIC_GROUP_NUMBER_OF_PEOPLE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function technicGroupReportListReducer(
  state: TechnicGroupReportListState = initialStateTechnicGroupReportList,
  action: memberActions,
): TechnicGroupReportListState {
  switch (action.type) {
    case GET_TECHNIC_GROUP_REPORT_SUCCESS: {
      return {
        ...state,
        technicGroupReportList: action.payload,
      };
    }
    case GET_TECHNIC_GROUP_REPORT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function technicalSelectTreeListReducer(
  state: TechnicalSelectTreeListState = initialStateTechnicalSelectTreeList,
  action: memberActions,
): TechnicalSelectTreeListState {
  switch (action.type) {
    case GET_TECHNICICAL_SELECT_TREE_LIST_SUCCESS: {
      return {
        ...state,
        technicalSelectTreeList: action.payload,
      };
    }
    case GET_TECHNICICAL_SELECT_TREE_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function mainTechnologiesListReducer(
  state: MainTechnologiesListState = initialStateMainTechnologiesList,
  action: memberActions,
): MainTechnologiesListState {
  switch (action.type) {
    case GET_MAIN_TECHNOLOGIES_LIST_SUCCESS: {
      return {
        ...state,
        mainTechnologiesList: action.payload,
      };
    }
    case GET_MAIN_TECHNOLOGIES_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function subTechnologiesListReducer(
  state: SubTechnologiesListState = initialStateSubTechnologiesList,
  action: memberActions,
): SubTechnologiesListState {
  switch (action.type) {
    case GET_SUB_TECHNOLOGIES_LIST_SUCCESS: {
      return {
        ...state,
        subTechnologiesList: action.payload,
      };
    }
    case GET_SUB_TECHNOLOGIES_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function domainListReducer(
  state: DomainListState = initialStateDomainList,
  action: memberActions,
): DomainListState {
  switch (action.type) {
    case GET_DOMAİN_LİST_SUCCESS: {
      return {
        ...state,
        domainList: action.payload,
      };
    }
    case GET_DOMAİN_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
//
export function languageListReducer(
  state: LanguageListState = initialStateLanguageList,
  action: memberActions,
): LanguageListState {
  switch (action.type) {
    case GET_LANGUAGE_LİST_SUCCESS: {
      return {
        ...state,
        languageList: action.payload,
      };
    }
    case GET_LANGUAGE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function languageLevelListReducer(
  state: LanguageLevelListState = initialStateLanguageLevelList,
  action: memberActions,
): LanguageLevelListState {
  switch (action.type) {
    case GET_LANGUAGE_LEVEL_LİST_SUCCESS: {
      return {
        ...state,
        languageLevelList: action.payload,
      };
    }
    case GET_LANGUAGE_LEVEL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function personalCommunicationLevelListReducer(
  state: PersonalCommunicationLevelListState = initialStatePersonalCommunicationLevelList,
  action: memberActions,
): PersonalCommunicationLevelListState {
  switch (action.type) {
    case GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_SUCCESS: {
      return {
        ...state,
        personalCommunicationLevelList: action.payload,
      };
    }
    case GET_PERSONAL_COMMUNİCATİON_LEVEL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberPersonalSkillListReducer(
  state: MemberPersonalSkillListState = initialStateMemberPersonalSkillList,
  action: memberActions,
): MemberPersonalSkillListState {
  switch (action.type) {
    case GET_MEMBER_PERSONAL_SKİLL_LİST_SUCCESS: {
      return {
        ...state,
        memberPersonalSkillList: action.payload,
      };
    }
    case GET_MEMBER_PERSONAL_SKİLL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function subTechnologiesListbyMainGroupStateReducer(
  state: SubTechnologiesListbyMainGroupState = initialStateSubTechnologiesListbyMainGroup,
  action: memberActions,
): SubTechnologiesListbyMainGroupState {
  switch (action.type) {
    case GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_SUCCESS: {
      return {
        ...state,
        subTechnologiesListbyMainGroup: action.payload,
      };
    }
    case GET_SUB_TECHNOLOGIES_LİST_BY_MAİN_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function technicGroupMainListReducer(
  state: TechnicGroupMainListState = initialStateTechnicGroupMainList,
  action: memberActions,
): TechnicGroupMainListState {
  switch (action.type) {
    case GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS: {
      return {
        ...state,
        technicGroupMainList: action.payload,
      };
    }
    case GET_TECHNİC_GROUP_MAİN_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function memberTechnicGroupListReducer(
  state: MemberTechnicGroupMainListState = initialStateMemberTechnicGroupList,
  action: memberActions,
): MemberTechnicGroupMainListState {
  switch (action.type) {
    case GET_MEMBER_TECHNİC_GROUP_LİST_SUCCESS: {
      return {
        ...state,
        memberTechnicGroupList: action.payload,
      };
    }
    case GET_MEMBER_TECHNİC_GROUP_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function statusStatisticListReducer(
  state: StatusStatisticListState = initialStateStatusStatisticList,
  action: memberActions,
): StatusStatisticListState {
  switch (action.type) {
    case GET_STATUS_STATİSTİC_LİST_SUCCESS: {
      return {
        ...state,
        statusStatisticList: action.payload,
      };
    }
    case GET_STATUS_STATİSTİC_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function statusStatisticbyMemberListReducer(
  state: StatusStatisticbyMemberListState = initialStateStatusStatisticbyMemberList,
  action: memberActions,
): StatusStatisticbyMemberListState {
  switch (action.type) {
    case GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS: {
      return {
        ...state,
        statusStatisticbyMemberList: action.payload,
      };
    }
    case GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allMemberListForPositionReducer(
  state: AllMemberListForPositionState = initialStateAllMemberListForPosition,
  action: memberActions,
): AllMemberListForPositionState {
  switch (action.type) {
    case GET_ALL_MEMBER_LİST_FOR_POSİTİON_SUCCESS: {
      return {
        ...state,
        allMemberListForPosition: action.payload,
      };
    }
    case GET_ALL_MEMBER_LİST_FOR_POSİTİON_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function domainMainGroupListReducer(
  state: DomainMainGoroupListState = initialStateDomainMainGoroupList,
  action: memberActions,
): DomainMainGoroupListState {
  switch (action.type) {
    case GET_DOMAİN_MAİN_GROUP_LİST_SUCCESS: {
      return {
        ...state,
        domainMainGroupList: action.payload,
      };
    }
    case GET_DOMAİN_MAİN_GROUP_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function domainSubGroupListReducer(
  state: DomainSubGoroupListState = initialStateDomainSubGoroupList,
  action: memberActions,
): DomainSubGoroupListState {
  switch (action.type) {
    case GET_DOMAİN_SUB_GROUP_LİST_SUCCESS: {
      return {
        ...state,
        domainSubGroupList: action.payload,
      };
    }
    case GET_DOMAİN_SUB_GROUP_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}