import styled from "styled-components";

import { Typography, Button, Select } from "@material-ui/core";

export const StyledSelect = styled(({ darkModeActive, ...props }) => (
  <Select {...props} />
))`
  && {
    border-color: #576176 !important;

    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "#3a475f" : "#fff"};
    color: ${({ darkModeActive }) =>
      darkModeActive ? "#fff" : "rgba(0, 0, 0, 0.87)"};

    .MuiOutlinedInput-root {
      border-color: ${({ darkModeActive }) =>
        darkModeActive ? "rgb(205, 212, 224)" : "#000"};
    }

    .MuiInputLabel-root {
      color: ${({ darkModeActive }) =>
        darkModeActive ? "#fff" : "rgba(0, 0, 0, 0.54)"};
    }
  }
`;

export const StyledHeader = styled(({ darkModeActive, ...props }) => (
  <Typography {...props} />
))`
  && {
    font-family: "IBM Plex Sans", sans-serif;
    variant: "h3";
    margin-bottom: 5px;
    line-height: 1.25;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(205, 212, 224)" : "#253858"};
  }
`;

export const Container = styled.div`
  margin: 25px 120px 5px;
`;

export const StyledContainer = styled(({ darkModeActive, ...props }) => (
  <div {...props} />
))`
  && {
    margin: 0px 110px 5px;
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "#3a475f" : "#fff"};
  }
`;

export const StyledButton = styled(({ darkModeActive, ...props }) => (
  <Button {...props} />
))`
  && {
    background-color: ${({ darkModeActive }) =>
      darkModeActive ? "rgb(40, 49, 67)" : "#e5e9f2"};
    color: ${({ darkModeActive }) => (darkModeActive ? "#fff" : "#1b2e4b")};
    transition: background-color 0.1s, color 0.1s;

    &:hover,
    &:focus {
      background-color: ${({ darkModeActive }) =>
        darkModeActive ? "rgb(40, 49, 67)" : "#c3cce1"};
    }
  }
`;
